import React, { useContext } from 'react';
import { TimelineTheme } from '../Timeline.enums';

interface ContextValue {
  activeItemIndex: number;
  setActiveItemIndex: React.Dispatch<React.SetStateAction<number>>;
  theme: TimelineTheme;
  inverse: boolean;
}

const TimelineContext = React.createContext<ContextValue>({
  activeItemIndex: 0,
  setActiveItemIndex: () => {},
  theme: TimelineTheme.DARK,
  inverse: false,
});

export const useTimelineContext = () => useContext(TimelineContext);

export default TimelineContext;
