import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img, { GatsbyImageFluidProps } from 'gatsby-image';

import Timeline, { TimelineTheme } from 'molecules/Timeline';
import styles from './AboutThilagam.module.scss';

const ABOUT_THILAGAM_QUERY = graphql`
  query AboutThilagamQuery {
    portrait: file(relativePath: { eq: "about/about-thilagam-portrait.png" }) {
      childImageSharp {
        fluid(maxWidth: 1224) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thilagamCarriers: file(
      relativePath: { eq: "about/about-thilagam-carriers.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thilagamStores: file(
      relativePath: { eq: "about/about-thilagam-stores.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rolfUebele: file(relativePath: { eq: "about/about-thilagam-uebele.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    germanyHeilbronn: file(
      relativePath: { eq: "about/about-thilagam-deutschland.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    karamponJaffna: file(
      relativePath: { eq: "about/about-thilagam-karampon.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kaytsMarket: file(
      relativePath: { eq: "about/about-thilagam-kayts-market.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thamaraiRestaurant: file(
      relativePath: { eq: "about/about-thilagam-restaurant.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface Data {
  portrait: {
    childImageSharp: GatsbyImageFluidProps;
  };
  thilagamCarriers: {
    childImageSharp: GatsbyImageFluidProps;
  };
  thilagamStores: {
    childImageSharp: GatsbyImageFluidProps;
  };
  rolfUebele: {
    childImageSharp: GatsbyImageFluidProps;
  };
  germanyHeilbronn: {
    childImageSharp: GatsbyImageFluidProps;
  };
  karamponJaffna: {
    childImageSharp: GatsbyImageFluidProps;
  };
  kaytsMarket: {
    childImageSharp: GatsbyImageFluidProps;
  };
  thamaraiRestaurant: {
    childImageSharp: GatsbyImageFluidProps;
  };
}

export interface Props {}

const AboutThilagam: React.FC<Props> = () => {
  const {
    portrait,
    thilagamCarriers,
    thilagamStores,
    rolfUebele,
    germanyHeilbronn,
    karamponJaffna,
    kaytsMarket,
    thamaraiRestaurant,
  } = useStaticQuery<Data>(ABOUT_THILAGAM_QUERY);

  return (
    <div className={styles[`AboutThilagam`]}>
      <div className={styles[`AboutThilagam__inner`]}>
        <div className={styles[`AboutThilagam__title`]}>In Andenken an</div>
        <div className={styles[`AboutThilagam__subtitle`]}>Thilagam</div>
        <div className={styles[`AboutThilagam__pariyari`]}>பரியாரி</div>
        <div className={styles[`AboutThilagam__lifespan`]}>1951 - 2020</div>
        <div className={styles[`AboutThilagam__portrait`]}>
          <Img
            fluid={portrait.childImageSharp.fluid}
            alt="Thilagam Soosaithasan Portrait"
          />
        </div>
        <Timeline
          theme={TimelineTheme.PRIMARY}
          inverse={true}
          initialActiveItemIndex={6}
        >
          <Timeline.Item
            year="1951"
            title="Born in Karampon"
            startIndex={0}
            endIndex={13}
          >
            <Img
              fluid={karamponJaffna.childImageSharp.fluid}
              alt="Karampon Map"
              className={`${styles[`AboutThilagam__image`]} ${
                styles[`AboutThilagam__image--karampon`]
              }`}
            />
            <div
              className={`${styles[`AboutThilagam__description`]} ${
                styles[`AboutThilagam__description--karampon`]
              }`}
            >
              Geboren in Karampon am 28.07.1951, einer wunderschönen Insel in
              Jaffna.
            </div>
            <div
              className={`${styles[`AboutThilagam__description`]} ${
                styles[`AboutThilagam__description--tamil`]
              } ${styles[`AboutThilagam__description--karampon`]}`}
            >
              நான்கு பக்கங்களும் கடலால் சூழப்பட்ட அழகுத்தீவாம் லைடன் தீவில்
              அமைந்துள்ள கரம்பொன் என்னும் அழகிய சிற்றூரில் 28.07.1951ல்
              பிறந்தார்.
            </div>
          </Timeline.Item>
          <Timeline.Item
            year="1965"
            title="Kayts Market: Obst- und Gemüsehandel"
            startIndex={14}
            endIndex={16}
            gapIndex={11}
          >
            <Img
              fluid={kaytsMarket.childImageSharp.fluid}
              alt="Kayts Market"
              className={`${styles[`AboutThilagam__image`]} ${
                styles[`AboutThilagam__image--market`]
              }`}
            />
            <div className={styles[`AboutThilagam__description`]}>
              Schulabbruch nach der 10. Klasse um die Familie finanziell zu
              unterstützen. Beginn einer Unternehmung im Einzelhandel zusammen
              mit der Mutter.
            </div>
            <div
              className={`${styles[`AboutThilagam__description`]} ${
                styles[`AboutThilagam__description--tamil`]
              }`}
            >
              சென் அன்ரனீஸ் கொலிச்சில் ஆண்டு பத்து வரை கல்வி பயின்றார்.
              குடும்பத்தினரின் வளர்ச்சிக்காக தாயாருடன் ஊர்காவற்றுறையிலுள்ள
              சந்தையில் மரக்கறி வியாபாரம் செய்தார்.
            </div>
          </Timeline.Item>
          <Timeline.Item
            year="1967"
            title="Thilagam Stores"
            startIndex={17}
            endIndex={20}
            gapIndex={10}
          >
            <Img
              fluid={thilagamStores.childImageSharp.fluid}
              alt="Thilagam Stores"
              className={`${styles[`AboutThilagam__image`]} ${
                styles[`AboutThilagam__image--store`]
              }`}
            />
            <div className={styles[`AboutThilagam__description`]}>
              Nach durchschlagendem Erfolg auf dem Obst-und Gemüsemarkt in Kayts
              erfolgt die Eröffnung eines eigenen Geschäfts im Alter von nur 17
              Jahren.
            </div>
            <div
              className={`${styles[`AboutThilagam__description`]} ${
                styles[`AboutThilagam__description--tamil`]
              }`}
            >
              இவரின் உழைப்பின் உயர்வினால் 17வது வயதில் திலகம் ஸ்ரோர்ஸ் என்ற
              வியாபார ஸ்தாபனத்தை ஊர்காவற்றுறையில் ஆரம்பித்தார்.
            </div>
          </Timeline.Item>
          <Timeline.Item
            year="1970"
            title="Thilagam Carriers"
            startIndex={20}
            endIndex={27}
            gapIndex={6}
          >
            <Img
              fluid={thilagamCarriers.childImageSharp.fluid}
              alt="Thilagam Carriers"
              className={`${styles[`AboutThilagam__image`]} ${
                styles[`AboutThilagam__image--carriers`]
              }`}
            />
            <div className={styles[`AboutThilagam__description`]}>
              Unternehmer Thilagam investiert seine ersten Einnahmen in den Kauf
              eines gebrauchten Transporter der britischen Marke Morris und
              startet parallel den Betrieb eines Transport-Geschäfts. Die
              Strecke von Kayts nach Jaffna Stadt erweist sich als eine
              lukrative Einnahmensquelle.
            </div>
            <div
              className={`${styles[`AboutThilagam__description`]} ${
                styles[`AboutThilagam__description--tamil`]
              }`}
            >
              இவரின் விடாமுயற்சியின் உழைப்புத் திறனால் பிரித்தானிய மொறிஸ்
              மினிபஸ் வாங்கினார். அதன் மூலம் ஊர்காவற்றுறை - யாழ்ப்பாணம்
              போக்குவரத்து தொழில் புரிந்தார்.
            </div>
          </Timeline.Item>
          <Timeline.Item
            year="1979"
            title="Kapitel Deutschland: Wirtschaftswunder"
            startIndex={28}
            endIndex={43}
          >
            <Img
              fluid={germanyHeilbronn.childImageSharp.fluid}
              alt="Wirtschaftswunder Deutschland"
              className={`${styles[`AboutThilagam__image`]} ${
                styles[`AboutThilagam__image--germany`]
              }`}
            />
            <div>1979 - 1981: &nbsp;&nbsp;&nbsp;Kali Chemie AG</div>
            <div>1982 - 1992: &nbsp;&nbsp;HUBER Packaging Group</div>
            <div>1993 - 1994: &nbsp;&nbsp;Hengstenberg GmbH</div>
            <br />
            <div
              className={`${styles[`AboutThilagam__description`]} ${
                styles[`AboutThilagam__description--tamil`]
              }`}
            >
              ஊரை விட்டு 1979ம் ஆண்டு ஜெர்மனிக்கு வந்தார். ஜெர்மனியில் பல
              நிறுவனங்களில் வேலை செய்தார்.
            </div>
          </Timeline.Item>
          <Timeline.Item
            year="1995"
            title="Rolf Uebele Stanz- und Rohrbiegetechnik"
            startIndex={44}
            endIndex={63}
          >
            <Img
              fluid={rolfUebele.childImageSharp.fluid}
              alt="Rolf Uebele"
              className={`${styles[`AboutThilagam__image`]} ${
                styles[`AboutThilagam__image--uebele`]
              }`}
            />
            <div className={styles[`AboutThilagam__description`]}>
              Beginn einer beruflichen Karriere als Maschinenarbeiter. Er
              sammelt 20 Jahre Erfahrung im Maschinenbau bei Rolf Uebele.
            </div>
            <div
              className={`${styles[`AboutThilagam__description`]} ${
                styles[`AboutThilagam__description--tamil`]
              }`}
            >
              1995ம் ஆண்டிலிருந்து றொல்ப் ஊய்பெல என்ற நிறுவனத்தில் இருபது
              ஆண்டுகளாக இயந்திரத் தொழிலாளியாக வேலை செய்தார்.
            </div>
          </Timeline.Item>
          <Timeline.Item
            year="2015"
            title="THAMARAI Restaurant Heilbronn"
            startIndex={64}
            endIndex={69}
            gapIndex={10}
          >
            <Img
              fluid={thamaraiRestaurant.childImageSharp.fluid}
              alt="THAMARAI Restaurant Heilbronn Illustration"
              className={`${styles[`AboutThilagam__image`]} ${
                styles[`AboutThilagam__image--restaurant`]
              }`}
            />
            <div className={styles[`AboutThilagam__description`]}>
              Pläne für das THAMARAI Restaurant Heilbronn beginnen im Jahr 2015.
              Nach umfangreichen Umbaumaßnahmen der eigenen vier Wände in eine
              Gaststätte, erfolgt die Eröffnung am 28. September 2019.
            </div>
            <div className={styles[`AboutThilagam__description`]}>
              Unternehmer Thilagam verstirbt am 19. Oktober 2020 zu Hause im
              Kreise der Familie.
            </div>
            <div
              className={`${styles[`AboutThilagam__description`]} ${
                styles[`AboutThilagam__description--tamil`]
              }`}
            >
              2015ம் ஆண்டிலிருந்து தனது சொந்த வீட்டிலேயே உணவகம் ஒன்றை
              ஆரம்பிப்பதற்கான கட்டமைப்பு வேலைகளைச் செய்து வந்தார். 28.09.2019
              இல் தாமரை உணவகத்தை சிறப்பாகவும் கோலாகலமாக திறந்து நடத்தி வந்தார்.
            </div>
            <div
              className={`${styles[`AboutThilagam__description`]} ${
                styles[`AboutThilagam__description--tamil`]
              }`}
            >
              19.10.2020 அன்று இறைவனடி எய்தினார்.
            </div>
          </Timeline.Item>
        </Timeline>
      </div>
    </div>
  );
};

export default AboutThilagam;
