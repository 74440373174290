import React, { useLayoutEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useTimelineContext } from './TimelineContext';
import styles from '../Timeline.module.scss';

export interface Props {
  children: React.ReactNode;
  startIndex: number;
  endIndex: number;
  gapIndex?: number;
  year: string;
  ref?: React.MutableRefObject<HTMLDivElement>;
  index?: number;
  title?: string;
}

const TimelineItem = React.forwardRef<HTMLDivElement, Props>(
  (
    { startIndex, endIndex, gapIndex = 0, year, title, index, children },
    ref
  ) => {
    const [indices, setIndices] = useState<(number | null)[]>([]);

    const contentStyle = useSpring({
      reset: true,
      from: { marginTop: 100 },
      to: { marginTop: 0 },
    });

    const { theme, inverse, activeItemIndex } = useTimelineContext();

    useLayoutEffect(() => {
      const indices = [];

      for (let i = startIndex; i <= endIndex; i++) {
        indices.push(i);
      }

      for (let j = 0; j < gapIndex; j++) {
        indices.push(null);
      }

      setIndices(indices);
    }, []);

    return (
      <div
        ref={ref}
        className={`${styles[`TimelineItem`]} ${
          styles[`TimelineItem--${theme}`]
        } ${index === activeItemIndex ? styles[`TimelineItem--active`] : ''} ${
          inverse ? styles['TimelineItem--inverse'] : ''
        }`}
      >
        <div className={styles[`TimelineItem__date`]}>
          <div className={styles[`TimelineItem__year`]}>{year}</div>
        </div>
        <ul className={styles[`TimelineItem__indices`]}>
          {indices.map((yearsPassed, idx) => (
            <li
              key={`${year}--${yearsPassed}--${idx}`}
              className={`${styles[`TimelineItem__index`]} ${
                styles[
                  `TimelineItem__index--${
                    yearsPassed === null
                      ? 'gap'
                      : idx === 0
                      ? '1st'
                      : idx === 1
                      ? '2nd'
                      : idx === 2
                      ? '3rd'
                      : idx === 3
                      ? '4th'
                      : idx === 4
                      ? '5th'
                      : 'tertiary'
                  }`
                ]
              }`}
            >
              {yearsPassed !== null && String(yearsPassed).padStart(2, '0')}
            </li>
          ))}
        </ul>
        {title && index === activeItemIndex && (
          <div className={styles[`TimelineItem__title`]}>{title}</div>
        )}
        {index === activeItemIndex && (
          <animated.div
            style={contentStyle}
            className={styles[`TimelineItem__content`]}
          >
            {children}
          </animated.div>
        )}
      </div>
    );
  }
);

export default TimelineItem;
