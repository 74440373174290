import React, { useState, useLayoutEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { useSwipeable } from 'react-swipeable';
import Icon, { IconSize } from '@soosap/sushi/Icon';

import { useKeyboardJs } from 'molecules/Keyboard';
import ArrowLeftIcon from 'icons/ArrowLeft';
import ArrowRightIcon from 'icons/ArrowRight';
import Item, { Props as TimelineItemProps } from './components/TimelineItem';
import TimelineContext from './components/TimelineContext';
import { TimelineTheme } from './Timeline.enums';
import styles from './Timeline.module.scss';

const OFFSET = 75;

interface FC<P = {}> extends React.FC<P> {
  Item: React.FC<TimelineItemProps>;
}

export interface Props {
  children: React.ReactElement<TimelineItemProps>[];
  initialActiveItemIndex?: number;
  inverse?: boolean;
  theme?: TimelineTheme;
}

const Timeline: FC<Props> = ({
  children,
  initialActiveItemIndex = 0,
  theme = TimelineTheme.DARK,
  inverse = false,
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number>(
    initialActiveItemIndex
  );
  const [left, setLeft] = useState<number>(0);
  const [height, setHeight] = useState<number | string>('initial');
  const [fixedHeight, setFixedHeight] = useState<number>(0);

  const innerStyle = useSpring({ height });
  const trackStyle = useSpring({ left });

  const timelineItems = React.Children.map(
    children,
    useRef
  ) as React.MutableRefObject<HTMLDivElement>[];

  useLayoutEffect(() => {
    setTimeout(() => {
      let updatedLeft = left;
      for (let i = 0; i < initialActiveItemIndex; i++) {
        const item = timelineItems[i];
        updatedLeft -= item.current.offsetWidth;
      }

      const activeItem = timelineItems[initialActiveItemIndex];
      const contentEl = activeItem.current.querySelector(
        `.${styles[`TimelineItem__content`]}`
      );

      if (contentEl) {
        setFixedHeight(
          activeItem.current.offsetHeight - contentEl.clientHeight
        );
      }

      setHeight(activeItem.current.offsetHeight - OFFSET);
      setLeft(updatedLeft);
    }, 0);
  }, []);

  const navigateNext = () => {
    const updatedActiveIndex = activeItemIndex + 1;

    if (updatedActiveIndex < React.Children.count(children)) {
      // Update height
      setTimeout(() => {
        const activeItem = timelineItems[updatedActiveIndex];
        const contentEl = activeItem.current.querySelector(
          `.${styles[`TimelineItem__content`]}`
        );
        if (contentEl) {
          const variableHeight = contentEl.clientHeight;
          const updatedHeight = fixedHeight + variableHeight - OFFSET;

          setHeight(updatedHeight);
        }
      }, 0);

      // Update left
      const itemToShift = timelineItems[activeItemIndex];
      const updatedLeft = left - itemToShift.current.offsetWidth;
      setLeft(updatedLeft);
      setActiveItemIndex(updatedActiveIndex);
    }
  };
  useKeyboardJs('right', navigateNext);

  const navigatePrev = () => {
    const updatedActiveIndex = activeItemIndex - 1;

    if (updatedActiveIndex >= 0) {
      // Update height
      setTimeout(() => {
        const activeItem = timelineItems[updatedActiveIndex];
        const contentEl = activeItem.current.querySelector(
          `.${styles[`TimelineItem__content`]}`
        );

        if (contentEl) {
          const variableHeight = contentEl.clientHeight;
          const updatedHeight = fixedHeight + variableHeight - OFFSET;

          setHeight(updatedHeight);
        }
      }, 0);

      // Update left
      const itemToShift = timelineItems[activeItemIndex - 1];
      const updatedLeft = left + itemToShift.current.offsetWidth;
      setLeft(updatedLeft);
      setActiveItemIndex(updatedActiveIndex);
    }
  };
  useKeyboardJs('left', navigatePrev);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: navigateNext,
    onSwipedRight: navigatePrev,
  });

  return (
    <TimelineContext.Provider
      value={{ activeItemIndex, setActiveItemIndex, theme, inverse }}
    >
      <div
        className={`${styles[`Timeline`]} ${
          theme ? styles[`Timeline--${theme}`] : ''
        }`}
      >
        <animated.div className={styles[`Timeline__inner`]} style={innerStyle}>
          <animated.div
            style={trackStyle}
            className={styles[`Timeline__track`]}
            {...swipeHandlers}
          >
            {React.Children.map(children, (child, index) => {
              return React.cloneElement(child, {
                index,
                ref: timelineItems[index],
              });
            })}
          </animated.div>
          {activeItemIndex !== 0 && (
            <div
              className={`${styles[`Timeline__navigation`]} ${
                styles[`Timeline__navigation--left`]
              }`}
              onClick={navigatePrev}
            >
              <Icon svg={ArrowLeftIcon} size={IconSize.MEDIUM} />
            </div>
          )}
          {activeItemIndex !== React.Children.count(children) - 1 && (
            <div
              className={`${styles[`Timeline__navigation`]} ${
                styles[`Timeline__navigation--right`]
              }`}
              onClick={navigateNext}
            >
              <Icon svg={ArrowRightIcon} size={IconSize.MEDIUM} />
            </div>
          )}
        </animated.div>
      </div>
    </TimelineContext.Provider>
  );
};

Timeline.Item = Item as React.FC<TimelineItemProps>;

export default Timeline;
