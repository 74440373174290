import React from 'react';

import Layout from 'molecules/Layout';
import AboutThilagam from 'organisms/AboutThilagam';

interface Props {}

const AboutThilagamPage: React.FC<Props> = () => {
  return (
    <Layout
      className="AboutPage"
      footerIcon={null}
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/ueber-uns/thilagam"
          coverImagePath="/about-thilagam-portrait.png"
          canonical="/ueber-uns/thilagam"
          title="In Andenken an Thilagam | Mitbegründer"
          lang="de-DE"
        />
      )}
      header={() => <Layout.Header logo={false} />}
    >
      <AboutThilagam />
    </Layout>
  );
};

export default AboutThilagamPage;
